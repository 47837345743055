import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Legal Notice" />

      <div className="flex flex-col flex-1 justify-center max-w-screen-lg lg:mx-auto w-max-screen px-4 space-y-10 text-center font-mono">
        <h1 className="container mt-8 text-[60px] lg:text-[80px] leading-[76.5%] tracking-[-0.085em] text-center uppercase">
          Legal Notice
        </h1>
        <div className="space-y-3">
          <p className="font-bold">According to § 5 TMG</p>
          <address className="not-italic">
            Holger Lippmann<br />
            Annenhof 1<br />
            16348 Wandlitz<br />
          </address>
        </div>

        <div className="space-y-3">
          <p className="font-bold">Contact</p>

          <ul className="list-none">
            <li>Telephone: +49 173 3940903</li>
            <li>Email: info@holgerlippmann.de</li>
          </ul>
        </div>
        
        <div className="space-y-3">
          <ul className="list-none">
            <li>Website made by: <a href="https://mobydigg.de" target="_blank">Moby Digg</a></li>
          </ul>
        </div>

        <div className="space-y-3">
          <ul className="list-none">
            <li>A project realized by: <a href="https://metadibs.io" target="_blank">Metadibs</a></li>
          </ul>
        </div>

        <div className="space-y-3">
          <p className="text-2xl my-8 font-bold">Disclaimer</p>

          <p><strong>No Investment Advice</strong></p>
          <p>The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the website's content as such. We do not recommend that any cryptocurrency should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decisions. Buy at your own risk.</p>
          <p></p>

          <p><strong>Accuracy of Information</strong></p>
          <p>We will strive to ensure accuracy of information listed on this website although it will not hold any responsibility for any missing or wrong information. We provide all information as is. You understand that you are using any and all information available here at your own risk.</p>
          <p></p>

          <p><strong>Non Endorsement</strong></p>
          <p>The appearance of third party advertisements and hyperlinks on our site does not constitute an endorsement, guarantee, warranty, or recommendation by us. Do conduct your own due diligence before deciding to use any third party services.</p>
          <p></p>
        </div>

        <div className="space-y-3">
          <p className="text-2xl my-8 font-bold">Data privacy</p>

          <div>
          <p> <strong> Dispute settlement </strong> </p>
            <p> We are neither willing nor obliged to participate in dispute settlement proceedings before a consumer arbitration board. </p>
            <p>&nbsp;</p>
            <p> <strong> Liability for content </strong> </p>
            <p> As a service provider, we are responsible for our own content on these pages in accordance with Section 7 Paragraph 1 of the German Telemedia Act (TMG) in accordance with general law. According to §§ 8 to 10 TMG, as a service provider, we are not obliged to monitor transmitted or stored third-party information or to research circumstances that indicate illegal activity. </p>
            <p> Obligations to remove or block the use of information according to general laws remain unaffected. However, liability in this regard is only possible from the point in time at which we become aware of a specific legal violation. As soon as we become aware of such legal violations, we will remove this content immediately. </p>
            <p>&nbsp;</p>
            <p> <strong> Liability for links </strong> </p>
            <p> Our offer contains links to external third-party websites, over whose content we have no influence. We can therefore not accept any liability for this third-party content. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time they were linked. No illegal content was found at the time the link was created. </p>
            <p> A permanent control of the content of the linked pages is not reasonable without concrete evidence of an infringement. As soon as we become aware of legal violations, we will remove such links immediately. </p>
            <p>&nbsp;</p>
            <p> <strong> Copyright </strong> </p>
            <p> The content and works on these pages created by the website operator are subject to German copyright law. The duplication, processing, distribution and any kind of exploitation outside the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. </p>
            <p> Insofar as the content on this site was not created by the operator, the copyrights of third parties are observed. In particular contents of third parties are marked as such. If you should nevertheless become aware of a copyright infringement, we ask that you notify us accordingly. If we become aware of legal violations, we will remove such content immediately. </p>
            <p>&nbsp;</p>
            <p> <strong> Data protection at a glance </strong> </p>
            <p>&nbsp;</p>
            <p> General information </p>
            <p> The following information provides a simple overview of what happens to your personal data when you visit our website. Personal data are all data with which you can be personally identified. Detailed information on the subject of data protection can be found in our data protection declaration below this text. </p>
            <p> Data collection on our website </p>
            <p> Who is responsible for data collection on this website? </p>
            <p> The data processing on this website is carried out by the website operator. You can find their contact details in the legal notice of this website. </p>
            <p>&nbsp;</p>
            <p> <strong> How do we collect your data? </strong> </p>
            <p> On the one hand, your data is collected when you communicate it to us. This can be, for example, data that you enter in a contact form. </p>
            <p> Other data are automatically recorded by our IT systems when you visit the website. This is mainly technical data (e.g. internet browser, operating system or time of the page was viewed). This data is collected automatically as soon as you enter our website. </p>
            <p>&nbsp;</p>
            <p> <strong> What do we use your data for? </strong> </p>
            <p> Some of the data is collected in order to ensure that the website is error-free. Other data can be used to analyze your user behavior. </p>
            <p>&nbsp;</p>
            <p> <strong> What rights do you have with regard to your data? </strong> </p>
            <p> You have the right to receive information about the origin, recipient and purpose of your stored personal data free of charge at any time. You also have the right to request the correction, blocking or deletion of this data. You can contact us at any time at the address given in the legal notice if you have any further questions on the subject of data protection. You also have the right to lodge a complaint with the responsible supervisory authority. </p>
            <p> You also have the right to request that the processing of your personal data be restricted under certain circumstances. Details can be found in the data protection declaration under "Right to restriction of processing". </p>
            <p>&nbsp;</p>
            <p> <strong> Analysis tools and third-party tools </strong> </p>
            <p> When you visit our website, your surfing behavior can be statistically evaluated. This is done primarily with cookies and so-called analysis programs. Your surfing behavior is usually analyzed anonymously; surfing behavior cannot be traced back to you. You can object to this analysis or prevent it by not using certain tools. You can find detailed information on this in the following data protection declaration. </p>
            <p> You can object to this analysis. We will inform you about the possibilities of objection in this data protection declaration. </p>
            <p>&nbsp;</p>
            <p> <strong> General information and mandatory information </strong> </p>
            <p> <em> Data protection </em> </p>
            <p> The operators of this website take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration. </p>
            <p> When you use this website, various personal data are collected. Personal data are data with which you can be personally identified. This data protection declaration explains which data we collect and what we use it for. It also explains how and for what purpose this is done. </p>
            <p> We would like to point out that data transmission over the Internet (e.g. when communicating by e-mail) can have security gaps. A complete protection of the data against access by third parties is not possible. </p>
            <p>&nbsp;</p>
            <p> <strong> Note on the responsible party </strong> </p>
            <p> The person responsible for data processing on this website is the contact mentioned above. </p>
            <p>&nbsp;</p>
            <p> The responsible body is the natural or legal person who, alone or jointly with others, decides on the purposes and means of processing personal data (e.g. names, email addresses, etc.). </p>
            <p>&nbsp;</p>
            <p> <strong> Revocation of your consent to data processing </strong> </p>
            <p> Many data processing operations are only possible with your express consent. You can revoke your consent at any time. An informal e-mail to us is sufficient. The legality of the data processing carried out up to the point of revocation remains unaffected by the revocation. </p>
            <p> Right to object to the collection of data in special cases and against direct advertising (Art. 21 GDPR) </p>
            <p> If the data processing takes place on the basis of Art. 6 Paragraph 1 lit. e or f GDPR, you have the right at any time to object to the processing of your personal data for reasons that arise from your particular situation; this also applies to profiling based on these provisions. The respective legal basis on which processing is based can be found in this data protection declaration. If you object, we will no longer process your personal data concerned, unless we can prove compelling reasons worthy of protection for the processing that outweigh your interests, rights and freedoms or the processing serves to assert, exercise or defend legal claims ( Objection according to Art. 21 Paragraph 1 GDPR). </p>
            <p> If your personal data are processed in order to operate direct mail, you have the right to object at any time to the processing of personal data concerning you for the purpose of such advertising; this also applies to profiling insofar as it is associated with such direct mail. If you object, your personal data will then no longer be used for direct marketing purposes (objection according to Art. 21 Paragraph 2 GDPR). </p>
            <p>&nbsp;</p>
            <p> <strong> Right of appeal to the competent supervisory authority </strong> </p>
            <p> In the event of violations of the GDPR, the data subject has the right to lodge a complaint with a supervisory authority, in particular in the member state of their habitual residence, their place of work or the place of the alleged violation. The right of appeal exists without prejudice to other administrative or judicial remedies. </p>
            <p>&nbsp;</p>
            <p> <strong> Right to data portability </strong> </p>
            <p> You have the right to have data that we process automatically on the basis of your consent or in fulfillment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another person responsible, this will only be done if it is technically feasible. </p>
            <p>&nbsp;</p>
            <p> <strong> Information, blocking, deletion and correction </strong> </p>
            <p> You have the right to free information about your stored personal data, their origin and recipient and the purpose of the data processing and, if necessary, the right to correct, block or delete this data at any time within the framework of the applicable legal provisions. You can contact us at any time at the address given in the legal notice if you have any further questions on the subject of personal data. </p>
            <p>&nbsp;</p>
            <p> <strong> Right to restriction of processing </strong> </p>
            <p> You have the right to request that the processing of your personal data be restricted. You can contact us at any time at the address given in the legal notice. The right to restriction of processing exists in the following cases: </p>
            <p> If you dispute the accuracy of your personal data stored by us, we usually need time to check this. For the duration of the test, you have the right to request that the processing of your personal data be restricted. <br /> If the processing of your personal data was / is unlawful, you can request that the data processing be restricted instead of deletion. <br /> If we no longer need your personal data, but you need them to exercise, defend or assert legal claims, you have the right to request that the processing of your personal data be restricted instead of being deleted. <br /> If you have lodged an objection in accordance with Art . 21 para. 1 GDPR, a balance must be made between your and our interests. As long as it has not yet been established whose interests prevail, you have the right to request that the processing of your personal data be restricted. <br /> If you have restricted the processing of your personal data, this data may only be used with your Consent or to assert, exercise or defend legal claims or to protect the rights of another natural or legal person or for reasons of an important public interest of the European Union or a member state. </p>
            <p>&nbsp;</p>
            <p> <strong> Objection to advertising emails </strong> </p>
            <p> We hereby object to the use of the contact data published in the context of the imprint obligation for sending unsolicited advertising and information materials. The operators of the pages expressly reserve the right to take legal action in the event of unsolicited sending of advertising information, such as spam e-mails. </p>
            <p>&nbsp;</p>
            <p> <strong> Data collection on our website </strong> </p>
            <p>&nbsp;</p>
            <p> Server log files </p>
            <p>&nbsp;</p>
            <p> The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are: </p>
            <p> Browser type and browser version <br /> Operating system used <br /><br /> Referrer URL <br /><br /> Host name of the accessing computer <br /> Time of the server request <br /> IP address <br /> A merging of this data with others Data sources will not be done. </p>
            <p> This data is collected on the basis of Art. 6 Para. 1 lit. f GDPR. The website operator has a legitimate interest in the technically error-free presentation and optimization of his website - the server log files must be recorded for this. </p>
            <p>&nbsp;</p>
            <p> <strong> Processing of data (customer and contract data) </strong> </p>
            <p>&nbsp;</p>
            <p> We collect, process and use personal data only insofar as they are necessary for the establishment, content or change of the legal relationship (inventory data). This is done on the basis of Art. 6 Paragraph 1 lit. b GDPR, which allows the processing of data for the performance of a contract or pre-contractual measures. We collect, process and use personal data about the use of our website (usage data) only insofar as this is necessary to enable the user to use the service or to bill. </p>
            <p> The customer data collected will be deleted after completion of the order or termination of the business relationship. Statutory retention periods remain unaffected. </p>
            <p> Data transfer when concluding a contract for online shops, dealers and dispatch of goods </p>
            <p> We only transfer personal data to third parties if this is necessary in the context of contract processing, for example to the company entrusted with the delivery of the goods or the credit institution commissioned with the payment processing. A further transmission of the data does not take place or only if you have expressly consented to the transmission. Your data will not be passed on to third parties without your express consent, e.g. for advertising purposes. </p>
            <p> The basis for data processing is Art. 6 Paragraph 1 lit. b GDPR, which allows the processing of data for the fulfillment of a contract or pre-contractual measures. </p>
            <p>&nbsp;</p>
            <p> <strong> Google Web Fonts </strong> </p>
            <p>&nbsp;</p>
            <p> This page uses so-called web fonts, which are provided by Google, for the uniform display of fonts. When you call up a page, your browser loads the required web fonts into your browser cache in order to display texts and fonts correctly. </p>
            <p> For this purpose, the browser you are using must connect to the Google servers. This gives Google knowledge that our website has been accessed via your IP address. The use of Google Web Fonts takes place in the interest of a uniform and appealing presentation of our online offers. This represents a legitimate interest within the meaning of Art. 6 Para. 1 lit. f GDPR. </p>
            <p> If your browser does not support web fonts, a standard font will be used by your computer. </p>
            <p> You can find more information about Google Web Fonts at <a href="https://developers.google.com/fonts/faq" target="_blank"> https://developers.google.com/fonts/faq </a> and in Google's privacy policy: <a href="https://www.google.com/policies/privacy/" target="_blank"> https://www.google.com/policies/privacy /. </a> </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
